import { useState } from "react"
import DashboardWrapper from "../../components/DashboardWrapper"
import { HeaderType1 } from "../../components/HeaderType1"
import '../../styles/General.css'
import { toast } from "react-toastify"
import { addDoc, collection } from "firebase/firestore"
import { db } from "../../Firebase"
import { useUser } from "../../context/User-Context"
import useFetchProfile from "../../hooks/use-fetch-profile"
import LineLoadingScreen from "../../components/LineLoadingScreen"
import { LoadingCircle } from "../../components/LoadingCircle"

const Feedback = () =>{
    const {currentUser} = useUser()
    const {profile , profileLoading} = useFetchProfile(currentUser?.uid)
    const [feedbackLoading , setFeedbackLoading] = useState(false)

    const [feedback , setFeedback] = useState('')

    if(!profile || profileLoading){
    
        return(
            <LineLoadingScreen/>
        ) 
    }
    console.log(profile)


    const handleSubmitFeedback = async () =>{
        if(!feedback){
            toast.error('Feedback is required')
            return;
        }
        try{
            setFeedbackLoading(true)
            const feedbackRef = collection(db, 'feedback');
            await addDoc(feedbackRef, { name: profile.name, feedback: feedback });
            toast.success('Feedback Submitted')
            setFeedback('')
        }
        catch(err){
            console.log(err)

        }
        finally{
            setFeedbackLoading(false)
        }
        console.log(feedback)
    }


    return (
        <DashboardWrapper>
        <HeaderType1 title='Feedback' />
        <div className="feedback">
            <span>Report a bug or Share Your Feedback:</span>
            <textarea onChange={(e)=> setFeedback(e.target.value)} value={feedback}>

            </textarea>
            <button disabled={feedbackLoading} onClick={handleSubmitFeedback}>{!feedbackLoading ? 'Submit' : <LoadingCircle/>}</button>

        </div>
        </DashboardWrapper>
    )
}

export default Feedback