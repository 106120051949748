import '../../styles/SignUp.css'
import useEmailPasswordSignup from '../../hooks/use-email-password-signup'
import { LoadingCircle } from '../../components/LoadingCircle'

const EmailVerification = ({userData,userCreated,setSteps}) =>{
 const { signupWithEmailPassword, secondSignupLoading } = useEmailPasswordSignup()



    return(
          <div className="email-verification-container">
            <div>
                <img src="'../../svgs/inbox.svg" alt="inbox"/>
            </div>
            <div>
                <p>Please check your email <span>{userData.email}</span> to verify your account, then click the button below to continue without refreshing the page.</p>
                <span onClick={()=>setSteps(7)}>change email</span>
            </div>
            <div>
              <button disabled={secondSignupLoading} onClick={()=>signupWithEmailPassword(userData,userCreated)}>{secondSignupLoading ?  <LoadingCircle/> : 'I have verified my email'}</button>
            </div>
        </div>
    )
}

export default EmailVerification