import { useParams } from "react-router-dom";
import DashboardWrapper from "../../components/DashboardWrapper";
import useFetchProfile from "../../hooks/use-fetch-profile";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { Link } from "react-router-dom";
import { IoSend } from "react-icons/io5";
import { useUser } from "../../context/User-Context";
import { useState, useRef,useEffect } from "react";
import useFetchChat from "../../hooks/use-fetch-chat";
import useAddChat from "../../hooks/use-add-chat";
import '../../styles/EachUserChat.css';
import LineLoadingScreen from "../../components/LineLoadingScreen";
import { useNavigate } from "react-router-dom";
import MenuOptions from "../../components/MenuOptions";


const EachUserChat = () => {
    const { currentUser } = useUser()
    const { id } = useParams();
    const [isMenuOpen , setIsMenuOpen] = useState(false)

    const navigate = useNavigate()
    const { chats } = useFetchChat(id)
    const { profile, profileLoading } = useFetchProfile(id);
    const [message, setMessage] = useState("")
    const {addChat} = useAddChat()
    const endRef = useRef(null);


    useEffect(() => {
        endRef.current?.scrollIntoView();
      }, [chats.messages]);

    if (profileLoading) {
        return <LineLoadingScreen/>;
    }
    

    const handleAdd =  () => {
        addChat(chats,chats.id,message,profile)
        setMessage('')
    };


    const options = [{name : 'Report'} , {name : 'Block'}]

    return (
        <DashboardWrapper>
            <div className="each-user-chat-header">
                <span onClick={()=> navigate(-1)}>
                        <MdOutlineKeyboardBackspace />
                </span>
                <div>
                    <div>
                        <img src={profile.userImages?.[0]} alt="user" />
                        <Link to={`../../eachuser/${profile.id}`}><span>{profile.name}</span>{profile?.isPremium && <img alt="verified" src="../svgs/verifiedicon.svg"/>}</Link>
                        
                    </div>
                    <div>
                    <img src={isMenuOpen ? "../svgs/UserMenuCancel.svg" : "../svgs/UserMenu.svg"} alt="menu" onClick={()=>setIsMenuOpen(prev => !prev)} />
                    </div>
                </div> 
                <div>
                  {isMenuOpen && <MenuOptions options={options}  />}
                </div>
            </div>
            
            <div className="each-user-chat-horizontal-line">
                <div></div>
            </div>
            
            <div className="chat-container">
                <div className="message-container">
                    {chats?.messages?.map((message,index)=>(
                        <div className={message.senderId === currentUser.uid ? 'message-own' : 'message'} key={index}>
                            <span>{message.text}</span>
                        </div>
                    ))}
                <div ref={endRef}></div>
                </div>
                <div className="chat-input">
                    <textarea
                        value={message} 
                        onChange={(e) => setMessage(e.target.value)}
                        placeholder="Type a message..."
                    />
                    <button onClick={handleAdd}>
                            <span><IoSend/></span>
                    </button>
                </div>
            </div>
        </DashboardWrapper>
    );
};

export default EachUserChat;
