import '../../styles/Login.css'
import {Link} from 'react-router-dom'
import Footer from '../landingPage/Footer'
import { LuEye } from "react-icons/lu";
import { LuEyeOff } from "react-icons/lu";
import { useState , useRef } from 'react';
import useEmailPasswordLogin from '../../hooks/use-email-password-login';
import { LoadingCircle } from '../../components/LoadingCircle';



const Login = () =>{
    const [password , setPassword] = useState('')
    const [email ,setEmail] = useState('')
    const [hashed , setHashed] = useState(false)

    const { loginWithEmailPassword , loginLoading , loginError , setLoginError } = useEmailPasswordLogin()

  const inputRef = useRef(null)

      const hashPassword = (e) =>{
        !hashed ? inputRef.current.type = 'text' : inputRef.current.type = 'password'
        setHashed(!hashed)
      }

      const HandleLogin = (e) =>{
        setLoginError('')
        e.preventDefault()
        loginWithEmailPassword(email,password)
      }


            return(
            <>
            <div className='top-login-header'>
                <div className='login-header'> 
                    <div><Link to='/'><img src='../images/logoname.png' alt='logoname'/></Link></div>
                    <div>
                        <div><span><Link to='/login'>Login</Link></span></div>
                        <div><span><Link to='/signup'>Sign Up</Link></span></div>

                    </div>
                </div>
            </div>
            <div className='login-body'>
                <img src='../images/logo.png' alt='logo'/>
                <img src='../images/logo.png' alt='logo'/>



                <div className='login-form'>
                    <div>
                        <span>Log In</span>
                    </div>
                    <form>
                        <div>
                            <input type='text' placeholder='Email' value={email} onChange={(e)=> setEmail(e.target.value)} />

                        </div>
                        <div>
                           <input type='password' value={password} ref={inputRef} placeholder='Password' onChange={(e)=> setPassword(e.target.value)} />
                           <span onClick={hashPassword}>{!hashed ? <LuEye/> : <LuEyeOff/>}</span>

                        </div>
                        <div>
                            <button disabled={loginLoading} onClick={(e)=>HandleLogin(e)}>{!loginLoading ? 'Log in' : <LoadingCircle/>}</button>
                        </div>
                        <p className='error'>{loginError}</p>
                        <div>
                            <span><Link to='/forgottenpassword'>Forgotten Password?</Link></span>
                        </div>
                        <div>
                            <span>Need an account?<Link to='/signup'>Sign up</Link></span>
                        </div>
                    </form>

                </div>
                  
            </div>
            <div className='login-footer'>
             <Footer/>
            </div>
            </>

    )

}
export default Login