import DashboardWrapper from "../../components/DashboardWrapper"
import { EmptyData } from "../../components/EmptyData"
import { HeaderType3 } from "../../components/HeaderType3"
import { useState } from "react"

const PremiumPage = () =>{
    const [isMenuOpen , setIsMenuOpen] = useState(false)

    return(
        <>
        
        <DashboardWrapper title='premium'>
            <HeaderType3 title='More Features!!' isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
        <EmptyData title={<>Premium features coming soon!!!</>}/>

        </DashboardWrapper>
        </>
    )
}

export default PremiumPage