import '../../styles/LandingPage.css'
import Header from './Header';
import Body from './Body';
import Footer from './Footer';
import GeneralLoadingScreen from '../../components/GeneralLoadingScreen';
import { useState, useEffect } from 'react';

function Home() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return <GeneralLoadingScreen />;
  }

  return (
    <>
      <Header />
      <Body />
      <Footer />
    </>
  );
}

export default Home;
