import { useState } from "react";
import {  doc, updateDoc } from "firebase/firestore";
import useFetchProfile from "./use-fetch-profile";
import { db } from '../Firebase'; 
import {  toast } from 'react-toastify';
import { useUser } from "../context/User-Context";



const useUpdateProfile = () => {
    const {currentUser} = useUser()
    const [updateProfileLoading, setUpdateProfileLoading] = useState(false);
    const {profile , profileLoading  } = useFetchProfile(currentUser?.uid)

    const updateProfile = async ( newUserData , setNewUserData ) => {
        if (profileLoading) {
            console.warn("Profile is still loading. Please wait.");
            return; 
        }

        try {
            setUpdateProfileLoading(true); 
            const ref = doc(db, 'users', profile.id);
            const cleanedData = cleanData(newUserData, profile);

            if(!cleanedData){
                setUpdateProfileLoading(false)
                return
            }
            
            await updateDoc(ref, cleanedData);
            toast.success('Profile Updated')
            //after successful update reset the setNewUserData state
            setNewUserData(
                {name : '' , dateOfBirth : '' , 
                    description : '' , 
                    contentPreference1 : '' , 
                    contentPreference2 : '' , region : ''
                }
            )
            setUpdateProfileLoading(false);
            console.log('Records updated');

        } catch (error) {
            console.error("Error updating profile:", error , 'good1');
            setUpdateProfileLoading(false);
            toast.error('Try again later')
            
        }
    };

    return { updateProfile, updateProfileLoading };
};

const cleanData = (newUserData = {}, profile) => {
    //clean the data
    const {
        contentPreference1, 
        contentPreference2,  
        ...rest
    } = newUserData;

   

    const contentArray = [
        contentPreference1 || profile?.content[0], 
        contentPreference2 || profile?.content[1]
    ];

     //remove falsy properties
    for(const key in rest){
        if(!rest[key]){
            delete rest[key]
        }
    }
    console.log(rest)
    //if no changes return false and early
    if(Object.keys(rest).length === 0  && !contentPreference1 && !contentPreference2){
        toast.info('No changes made')
        return false
    }

    if(rest.country && !rest.region){
        toast.info('Region is required')
        return false
    }


    return {
        ...rest,
        content: contentArray,
    };
};



export default useUpdateProfile;
