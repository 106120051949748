import { auth } from "../Firebase"
import { useNavigate } from "react-router-dom";

const useSignOut  = () =>{
    const navigate = useNavigate()

     const signOut = async() =>{
        try {
            await auth.signOut();
            localStorage.removeItem("scrollPosition")
            console.log("User signed out successfully");
            navigate('/')

        } catch (error) {
            console.error("Error signing out: ", error);
        }
     }

     return { signOut }
}

export default useSignOut