import DashboardWrapper from "../../components/DashboardWrapper"
import useFetchProfile from "../../hooks/use-fetch-profile";
import { useEffect, useState  } from "react";
import useUpdateProfile from "../../hooks/use-update-profile";
import '../../styles/EditProfile.css'
import { useUser } from "../../context/User-Context";
import LineLoadingScreen from "../../components/LineLoadingScreen";
import { LoadingCircle } from "../../components/LoadingCircle";
import { HeaderType2 } from "../../components/HeaderType2";



const EditProfile = () =>{
    const {currentUser} = useUser()
    const {profile , profileLoading  } = useFetchProfile(currentUser?.uid)
    const [isMenuOpen , setIsMenuOpen] = useState(false)


    const  { updateProfile , updateProfileLoading } = useUpdateProfile()
    const [name , setName]  = useState('')
    const [editName , setEditName] = useState(false)
    const [dateOfBirth , setdateOfBirth]  = useState('')
    const [editdateOfBirth , setEditdateOfBirth] = useState(false)
    const [description , setDescription]  = useState('')
    const [editDescription , setEditDescription] = useState(false)
    const [contentPreference1 , setContentPreference1]  = useState('')
    const [editContentPreference1 , setEditContentPreference1] = useState(false)
    const [contentPreference2 , setContentPreference2]  = useState('')
    const [editContentPreference2 , setEditContentPreference2] = useState(false)
    const [region , setRegion]  = useState('')
    const [editRegion , setEditRegion] = useState(false)
    const [country , setCountry]  = useState('')
    const [editCountry , setEditCountry] = useState(false)
    const [selectedCountry , setSelectedCountry] = useState(null)
    const [newUserData , setNewUserData] = useState({name : '' , dateOfBirth : '' , description : '' , contentPreference1 : '' , contentPreference2 : '' , region : ''})


    useEffect(()=>{
        setSelectedCountry(profile?.country)
    },[profile])

    console.log(selectedCountry)

    if(profileLoading){
        return <LineLoadingScreen/>;
    }

   
    

    const countries = [
        {
            name: 'United Kingdom',
            regions: [
                "Birmingham", "Brighton and Hove", "Bristol", "Cambridge", "Cardiff",
                "Chester", "Coventry", "Derby", "Edinburgh", "Exeter",
                "Glasgow", "Hull", "Leeds", "Leicester", "Lichfield",
                "Liverpool", "London", "Manchester", "Milton Keynes", "Newcastle upon Tyne",
                "Norwich", "Nottingham", "Oxford", "Plymouth", "Portsmouth",
                "Sheffield", "Southampton", "Stoke-on-Trent", "Wakefield", "Wolverhampton",
                "York"
            ]
        },
        {
            name: 'United States',
            regions: [
                "Alabama", "Alaska", "Arizona", "Arkansas", "California", "Colorado", "Connecticut",
                "Delaware", "Florida", "Georgia", "Hawaii", "Idaho", "Illinois", "Indiana", "Iowa",
                "Kansas", "Kentucky", "Louisiana", "Maine", "Maryland", "Massachusetts", "Michigan",
                "Minnesota", "Mississippi", "Missouri", "Montana", "Nebraska", "Nevada", "New Hampshire",
                "New Jersey", "New Mexico", "New York", "North Carolina", "North Dakota", "Ohio",
                "Oklahoma", "Oregon", "Pennsylvania", "Rhode Island", "South Carolina", "South Dakota",
                "Tennessee", "Texas", "Utah", "Vermont", "Virginia", "Washington", "West Virginia",
                "Wisconsin", "Wyoming"
            ]
        }
    ];
    
    

  
    
    
    const content = [
        'Animation',
        'Cars Vlogging',
        'Coding',
        'Dancing',
        'Food Vlogging',
        'Fashion',
        'Game Streaming',
        'Gym / Fitness',
        'Haul Videos',
        'Lifestyle Vlogging',
        'Makeup Tutorials',
        'Music',
        'Photography',
        'Podcasts',
        'Tech Vlogging',
        'Travel Vlogging',
        'Video Streaming',
        'Vlogging',
    ];

    const content0 = content.filter(content => content !== profile.content[0])
    const content1 = content.filter(content => content !== profile.content[1])





  
    const handleName = (e) => {
        const {name , value} = e.target
        setName(value);        
        setEditName(true);     
        setNewUserData(prev => ({
            ...prev,
            [name]: value,
          })); 
    };
    const handledateOfBirth = (e) => {
        const {name , value} = e.target
        setdateOfBirth(value);        
        setEditdateOfBirth(true);    
        setNewUserData(prev => ({
            ...prev,
            [name]: value,
          }));  
    };
    const handleDescription = (e) => {
        const {name , value} = e.target
        setDescription(value);        
        setEditDescription(true);   
        setNewUserData(prev => ({
            ...prev,
            [name]: value,
          }));   
    };

    const handleContentPreference1 = (e) =>{
        const {name , value} = e.target
        setContentPreference1(value)
        setEditContentPreference1(true)
        setNewUserData(prev => ({
            ...prev,
            [name]: value,
          })); 
    }
    const handleContentPreference2 = (e) =>{
        const {name , value} = e.target

        setContentPreference2(value)
        setEditContentPreference2(true)
        setNewUserData(prev => ({
            ...prev,
            [name]: value,
          })); 
    }
    const handleRegion = (e) => {
        const {name , value} = e.target

        setRegion(value);        
        setEditRegion(true);     
        setNewUserData(prev => ({
            ...prev,
            [name]: value,
          })); 
    };

    const handleCountry = (e) => {
        const {name , value} = e.target

        setCountry(value);        
        setEditCountry(true);     
        setNewUserData(prev => ({
            ...prev,
            [name]: value,
          })); 
        setSelectedCountry(value);
        setEditRegion(true)


    };

console.log(newUserData)
    const reset = () =>{

        setName('')
        setEditName(false)
        setdateOfBirth('')
        setEditdateOfBirth(false)
        setDescription('')
        setEditDescription(false)
        setContentPreference1('')
        setEditContentPreference1(false)
        setContentPreference2('')
        setEditContentPreference2(false)
        setRegion('')
        setEditRegion(false)
        setNewUserData({name : '' , dateOfBirth : '' , description : '' , 
            contentPreference1 : '' , contentPreference2 : '' , region : ''})

    }

    const update = () =>{
        updateProfile(newUserData , setNewUserData)

    }

    const Reset  = () =>{
        reset()
        setIsMenuOpen(false)
    }

    const options = [{name : 'Reset' , action : Reset}]
   
    return(
        <DashboardWrapper>
            <div className="edit-profile-header">
            <HeaderType2 title='Edit Profile' options={options} isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
            </div>
            <div className="edit-profile-text">
                <span>Profile Settings</span>
            </div>
            <div className="edit-profile-inputs">
                <div>
                    <span>Name</span>
                    <input  type="text" name='name'  value={!editName ? profile.name : name} placeholder="Name" onChange={(e) => handleName(e)} />
                </div>
                <div>
                    <span>D.O.B</span>
                    <input type="date"  name='dateOfBirth' className="edit-profile-date" value={!editdateOfBirth ? profile.dateOfBirth : dateOfBirth} onChange={(e) => handledateOfBirth(e)} />
                </div>
                <div>
                    <span>Bio</span>
                    <textarea type="text" name='description' value={!editDescription ? profile.description : description} onChange={(e) => handleDescription(e)} />
                </div>
                <div>
                    <span>Content-type-1</span>
                    <select name="contentPreference1"  value={!editContentPreference1 ? profile.content[0] : contentPreference1}  onChange={(e) => handleContentPreference1(e)}>
                        <option selected={!editContentPreference1} value={profile.content[0] || null}  >{profile.content[0] ? profile.content[0] : 'Not-selected'}</option>
                        {content0?.map((content,index)=>(
                            <option value={content} key={index}>{content}</option>
                        ))}
                    </select>
                </div>
                <div>
                    <span>Content-type-2</span>
                    <select name="contentPreference2"  value={!editContentPreference2 ? profile.content[1] : contentPreference2} onChange={(e) => handleContentPreference2(e)}>
                        <option selected={!editContentPreference2} value={ profile.content[1] || null}>{profile.content[1] ? profile.content[1] : 'Not-selected'}</option>
                        {content1?.map((content,index)=>(
                            <option value={content} key={index}>{content}</option>
                        ))}
                    </select>
                </div>
                <div>
                    <span>Country</span>
                    <select name='country'  value={!editCountry ? profile?.country : country}  onChange={(e) => handleCountry(e)}>
                        <option selected value={profile?.country} disabled>{profile?.country}</option>
                        {countries.map((content,index)=>(
                            <option value={content.name} key={index}>{content.name}</option>
                        ))}
                    </select>
                </div>

                <div>
                    <span>Region</span>
                    <select name='region' disabled={!editRegion} value={!editRegion ? profile?.region : region}  onChange={(e) => handleRegion(e)}>
                        <option selected></option>
                        {countries.find(country => country.name === selectedCountry)?.regions?.map((content,index)=>(
                            <option value={content} key={index}>{content}</option>
                        ))}
                    </select>
                </div>
                
            </div>
            <div className="edit-profile-button">
                <button disabled={updateProfileLoading} onClick={update}>{!updateProfileLoading ?'Save' : <LoadingCircle/>}</button>
            </div>
        </DashboardWrapper>
    )
}

export default EditProfile