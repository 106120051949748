import DashboardWrapper from "../../components/DashboardWrapper"
import '../../styles/AccountSettings.css'
import { HeaderType1 } from "../../components/HeaderType1";
import { Link } from "react-router-dom";


const AccountSettings = () =>{
    return(
        <DashboardWrapper>
            <div className="account-settings-header">
                <HeaderType1 title='Account Settings'/>
            </div>
            <div className="account-settings-container">
                {/* <div>
                    <span><Link>Update Email Address</Link></span>
                    <img src="../svgs/rightarrow.svg" alt="right-arrow"  />
                </div> */}
                <div>
                    <Link to='/changepassword'>
                        <span>Change Password</span>
                        <img src="../svgs/rightarrow.svg" alt="right-arrow"  />
                    </Link>
                </div>
                <div>
                    <Link>
                        <span>Manage Subscriptions</span>
                        <img src="../svgs/rightarrow.svg" alt="right-arrow"  />
                    </Link>
                </div>
            </div>
        </DashboardWrapper>
    )
}

export default AccountSettings