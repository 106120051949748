

const Name = ({ userData , HandleChangeForm , error }) =>{

    return(
        <>
        <div className='name-signup-container'>
            <p>Lets get started 😃</p>
            <p>What can we call you?</p>
            <input type="text" value={userData.name || null} name="name" onChange={(e) => HandleChangeForm(e)}/>
            <p className='error'>{error}</p>
        </div>
        
        </>
    )
}

export default Name