import { useEffect, useState } from "react";
import { useUser } from "../context/User-Context";
import { doc, onSnapshot, getDoc } from "firebase/firestore";
import useFetchProfile from "./use-fetch-profile";
import { db } from "../Firebase";

const useFetchUserChats = () => {

    
    const { currentUser } = useUser();
    const { profile, profileLoading } = useFetchProfile(currentUser?.uid);
    const [userChats, setUserChats] = useState([]);
    const [userChatsLoading, setUserChatsLoading] = useState(true);

    useEffect(() => {
        if (profileLoading || !profile) {
            setUserChatsLoading(true); 
            return;
        }
        const userChatRef = doc(db, 'userchats', profile.id);

        const unsubscribe = onSnapshot(
            userChatRef,
            async (snapshot) => {
                //we want to show the receiver photo and name for each receiver
                // so we use the reciever id to fetch thier profile and return them in a good structure
                if (snapshot.exists()) {
                    const items = snapshot.data().chats;
                    const promises = items?.filter(item => item.isShown).map(async (item) => {
                        const userDocRef = doc(db, 'users', item.receiverId);
                        const userDocSnap = await getDoc(userDocRef);
                        const user = userDocSnap.data();
                        return { ...item, user };
                    });

                    const chatData = await Promise.all(promises);
                    setUserChats(chatData.sort( (a,b) => b.updatedAt - a.updatedAt));
                     
                } else {
                    console.log("No user chat data found for this profile.");
                    setUserChats([]);
                }
                setUserChatsLoading(false);
            },
            (error) => {
                console.error("Error fetching user chats:", error);
                setUserChats([]);
                setUserChatsLoading(false);
            }
        );

        return () => unsubscribe();

    }, [currentUser, profile, profileLoading]);

    return { userChats, userChatsLoading };
};

export default useFetchUserChats;
