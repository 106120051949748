import { useState , useEffect } from "react";
import { Link } from "react-router-dom";

const Body = () => {
    
    
    const reviewArray = [{name : 'Alex' , review : "Before discovering Vid-Meet, I was hesitant and shy about creating fashion content. Despite having a passion for fashion ansd plenty of ideas the thought of shooting content felt overwhelming. That all changed when I found the perfect partner through this platform"} ,
        {name : 'John' , review : "Joining Vid-Meet transformed my streaming journey! I connected with an awesome co-streamer, and our live streams exploded. Together, we gained thousands of followers and reached new milestones faster than I ever expected. Vid-Meet made finding the perfect partner so easy!"},
        {name : 'Alisha' , review :"Before joining Vid-Meet, launching a podcast felt like a solo uphill climb. Then I connected with the perfect co-host, and everything changed. We quickly hit a million views, built a loyal following, and finally made our dream podcast a reality. Vid-Meet made it easy to find the right partner!"}]

    const [name, setName] = useState(reviewArray[0].name);
    const [review , setReview] = useState(reviewArray[0].review);
    const [index, setIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setIndex((prevIndex) => {
                const newIndex = (prevIndex + 1) % reviewArray.length;
                setName(reviewArray[newIndex].name)
                setReview(reviewArray[newIndex].review)
                return newIndex; 
            });
        }, 7000);

        return () => clearInterval(interval);
    }, []);

   


    return ( 
    <>  <div className="body-1">
            <div className="main1">
                <div className="main1-container-1">
                    <img src="../images/logo.png" alt="bgpicture"/>
                    <img src="../images/logo.png" alt="bgpicture"/>
                    <div><span>Collaborate and</span><span>create</span><span>something extraordinary</span></div>
                    <div><Link to='/signup'>Sign up</Link></div>
                    <div><span>Already a member?</span><Link to='/login'><span>Log in</span></Link></div>
                </div>
                <div className="main1-container-2">
                    <img src="../images/bg.png" alt="bgpicture"/>
                </div>

            </div>
        </div>
        <div className="body-2">
            <div className="main-2">
                    <div><img src="../images/logo.png" alt="sds" /></div>
                    <div>
                        <div><span>How it works</span></div>
                        <div><span>Every content creator's journey is unique, so we’re giving you vid-meet the networking app for content creators to connect and collaborate 
                            in a way that fits your style. Whether you prefer reaching out to fellow creators directly or exploring different 
                            project opportunities, we’ve got a variety of features to make teaming up easy and fun. 
                        </span></div>
                    </div>

            </div>

        </div>
        <div className="body-3">
            <div className="main-3">
            <div>
                <img src="../images/logo.png" alt="sds" />

                <div><span>{name}</span></div>
                <div><span>{review}</span>
                </div>
                <div>
                    <div className={`${index === 0 ? 'slideshow-active' : 'slideshow-not-active'}`}></div>
                    <div className={`${index === 1 ? 'slideshow-active' : 'slideshow-not-active'}`}></div>
                    <div className={`${index === 2 ? 'slideshow-active' : 'slideshow-not-active'}`}></div>


                </div>
                
            </div>
            <div>
                <img src="../images/pic1.png" alt="sds" /></div>
            </div>

        </div>

    </>
     );
}
 
export default Body;