import { useState } from "react";
import Name from './steps/Name';
import GenderAge from './steps/Gender-Age';
import '../../styles/SignUp.css';
import Footer from "../../pages/landingPage/Footer";
import Location from "./steps/Location";
import Content from "./steps/Content";
import EthnicityAbout from "./steps/Ethnicity-About";
import EmailPassword from "./steps/Email-Password";
import Image from "./steps/Image";
import { Link } from "react-router-dom";
import { LoadingCircle } from "../../components/LoadingCircle";
import { auth } from "../../Firebase";
import { createUserWithEmailAndPassword , sendEmailVerification,signInWithEmailAndPassword , deleteUser } from 'firebase/auth';
import EmailVerification from "./EmailVerification";
import { CheckEmoji } from "../../components/CheckEmoji";


const SignUp = () => {
  const [error, setError] = useState('');
  const [steps, setSteps] = useState(1);
  const [userData, setUserData] = useState({
    name: '',   
    gender: '',
    dateOfBirth: '',
    ethnicity: '',
    description: '',
    country: '',
    region: '',
    contentPreference1: '',
    contentPreference2: '',
    userImage : null,
    email: '',
    password: ''
  });
  const [firstSignupLoading , setFirstSignupLoading] = useState(false);
  const [userCreated , setUserCreated] = useState(null)

  const HandleChangeForm = (e) => {
    const { name, value, files } = e.target;
    
    if (name === 'userImage' && files) {
      setUserData(prev => ({
        ...prev,
        [name]: files[0],
      }));
    } else {
      setUserData(prev => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const SubmitEmail = async () => {
    try {
        setFirstSignupLoading(true);

        const userCredential = await createUserWithEmailAndPassword(auth, userData.email, userData.password);

        await sendEmailVerification(userCredential.user);

        setUserCreated(userCredential.user);
        setSteps((prev) => prev + 1);
    } catch (error) {
        console.error("Error during signup:", error);

        if (error.code === 'auth/email-already-in-use') {
            // Check if email is unverified
            const userQuery = await signInWithEmailAndPassword(auth, userData.email, userData.password).catch(() => null);
            if (userQuery && !userQuery.user.emailVerified) {
                await deleteUser(userQuery.user);
                setError('Last signup wasn’t completed. Please sign up again.');
            } else {
                setError('Email already in use.');
            }
        } else if (error.code === 'auth/invalid-email') {
            setError('Please enter a valid email address');
        } else if (error.code === 'auth/missing-password') {
            setError('Please enter a password');
        } else if (error.code === 'auth/weak-password') {
            setError('Password should be at least 6 characters');
        } else {
            setError(error.message);
        }
    } finally {
        setFirstSignupLoading(false);
    }
};




  const HandleSteps = () => {
    if (steps === 1) {
      return <Name userData={userData} HandleChangeForm={HandleChangeForm} error={error} />;
    } else if (steps === 3) {
      return <GenderAge userData={userData} HandleChangeForm={HandleChangeForm} error={error}  />;
    } else if (steps === 4) {
      return <EthnicityAbout userData={userData} HandleChangeForm={HandleChangeForm} error={error} />;
    } else if (steps === 5) {
      return <Location userData={userData} HandleChangeForm={HandleChangeForm} error={error} />;
    } else if (steps === 2) {
      return <Content userData={userData} HandleChangeForm={HandleChangeForm} error={error} />;
    }
    else if (steps === 6) {
      return <Image userData={userData} HandleChangeForm={HandleChangeForm} error={error} />;
    }
    else if (steps === 7) {
      return <EmailPassword userData={userData} HandleChangeForm={HandleChangeForm} error={error} />;
    }else if(steps === 8){
      return <EmailVerification userData={userData} userCreated={userCreated} setSteps={setSteps} />
    }
  };

  const ValidateAge = (date) => {
    const today = new Date();
    const birthDate = new Date(date);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    return age >= 15;
  };

  const validateForm = () => {
    if (steps === 1 && userData.name.trim() === '') {
      setError('Please enter your name!');
      return false; 
    }
    if (steps === 1 && userData.name.includes(' ')) {
      setError('Name cannot include space');
      return false; 
    }
    if (steps === 1 && userData.name && CheckEmoji(userData.name.trim())) {
      setError('Name cannot include emoji');
      return false;
    }
  
    if (steps === 3 && userData.gender.trim() === '') {
      setError('Gender is required!');
      return false;
    }
    if (steps === 3 && userData.dateOfBirth.trim() === '') {
      setError('Date of birth is required!');
      return false;
    }
    if (steps === 3 && !ValidateAge(userData.dateOfBirth)) {
      setError('Age must be over 15!');
      return false;
    }
    if (steps === 4 && !userData.ethnicity) {
      setError('Please select your ethnicity');
      return false;
    }
    if (steps === 4 && !userData.description) {
      setError('Please tell us about yourself');
      return false;
    }
    if (steps === 5 && !userData.country){
      setError('Please select a country!');
      return false;
    }
    if (steps === 5 && !userData.region) {
      setError('Please select a region!');
      return false;
    }
    if (steps === 2 && (!userData.contentPreference1 && !userData.contentPreference2)) {
      setError('Select at least one content type!');
      return false;
    } 
    if(steps === 6 && !userData.userImage){
      setError('Select an image!');
      return false;
    }
    setError('');
    return true; 
  };

  

  const NextStep = (e) => {
    e.preventDefault();
    console.log(userData);
    console.log(steps);
    if (validateForm()) {
      if (steps === 7) {
        SubmitEmail();
      } else {
        setSteps((prev) => prev + 1);
      }
    }
  };
  

  const PrevStep = () => {
    setError('');
    setSteps(steps - 1);
    console.log(steps);

  };

  return (
    <>
      <div className="top-signup-static-header">
        <div className="signup-static-header">
          <div>
            <Link to='/'><img src="../images/logo.png" alt="logo" /></Link>
            <Link className="custom-signup-header" to='/'><img className="custom-signup-header-img"  src="../images/logoname.png" alt="logo-name-alt" /></Link>
          </div>
          <div>
          <p>{steps < 8 && <>{steps}<span> / 7</span></>}</p>
          </div>
        </div>
      </div>
      <div className="top-signup-container">
        <div className="signup-container">
          <div className="steps">
            {HandleSteps()}
          </div>
        
          {steps < 8 && <div className="control-steps">
            {steps !== 1 && <button disabled={firstSignupLoading} onClick={PrevStep}>Prev</button>}
            <button disabled={firstSignupLoading} onClick={(e) => NextStep(e)}>{firstSignupLoading  ? <LoadingCircle/> : 'Next'}</button>
         </div> }
        </div>
      </div>
      <div className="signup-footer">
        <Footer />
      </div>
    </>
  );
};

export default SignUp;
