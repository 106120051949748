
const Content = ({userData , HandleChangeForm, error }) => {

    
    const content = [
        'Animation',
        'Cars Vlogging',
        'Coding',
        'Dancing',
        'Food Vlogging',
        'Cooking',
        'Fashion',
        'Game Streaming',
        'Gym / Fitness',
        'Haul Videos',
        'Lifestyle Vlogging',
        'Makeup Tutorials',
        'Music',
        'Photography',
        'Podcasts',
        'Tech Vlogging',
        'Travel Vlogging',
        'Video Streaming',
        'Vlogging',
    ];
    
        
    
   

    return (
        <div className="location-signup-container">
            <h1>What type of content would you collaborate on?</h1>
            <p>Select your preferred content type 1:</p>
            <select name="contentPreference1" onChange={HandleChangeForm} value={userData.contentPreference1 || null} >
                <option value={''} disabled selected></option>
                {content.map((content,index) => (
                    <option key={index} value={content}>
                        {content}
                    </option>
                ))}
            </select>

            <p>Select your preferred content type 2:</p>
            <select name="contentPreference2" onChange={HandleChangeForm} value={userData.contentPreference2 || null} >
                <option value={''} disabled selected></option>
                {content.map((content,index) => (
                    <option key={index} value={content}>
                        {content}
                    </option>
                ))}
            </select>

            <p className="error">{error}</p>
        </div>
    );
};

export default Content;
