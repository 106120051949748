export const GetTime = (date) => {
    let output;
    const dateToFormat = new Date(date); 
    const presentDate = Date.now();

    // if (isNaN(dateToFormat)) {
    //     console.error('Invalid date provided.');
    //     return 'Invalid Date';
    // }

    const timeDifference = presentDate - dateToFormat.getTime();

    if (timeDifference < 24 * 60 * 60 * 1000) {
        // If the date is less than 24 hours old

        let preOutput = dateToFormat.toLocaleTimeString(undefined, {
            hour12: true,
            hour: '2-digit',
            minute: '2-digit',
        });
        if(preOutput.endsWith('AM')){
            console.log('true')
            output = preOutput.slice(0, preOutput.length - 2).concat('am')
            
        }else{
            output = preOutput.slice(0, preOutput.length - 2).concat('pm')
        }

    } 
    else if (timeDifference >= 24 * 60 * 60 * 1000 && timeDifference < 2 * 24 * 60 * 60 * 1000) {
        // If the date is greater than 24 hours but less than 48 hours
        output = 'Yesterday'
    }
    else if (timeDifference >= 24 * 60 * 60 * 1000 && timeDifference < 7 * 24 * 60 * 60 * 1000) {
        // If the date is greater than 24 hours but less than a week
        output = dateToFormat.toLocaleDateString(undefined, { weekday: 'long' });
    } else {
        // If the date is greater than a week
        output = dateToFormat.toLocaleDateString(undefined, {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric',
        });
    }

    return output; 
};
