import { signInWithEmailAndPassword, deleteUser } from "firebase/auth";
import { auth } from '../Firebase'; 
import { getDoc, doc } from 'firebase/firestore';
import { db } from '../Firebase';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const useEmailPasswordLogin = () => {
  const [loginLoading , setLoginLoading] = useState(false)
  const [loginError , setLoginError] = useState('')

  const navigate = useNavigate();



  const loginWithEmailPassword = async ( email , password) => {

    try {
      setLoginLoading(true)
      const userCredential = await signInWithEmailAndPassword(auth , email , password)
      const userRef = doc(db , 'users', userCredential.user.uid)
      const userDoc = await getDoc(userRef)
      console.log(userCredential,'good')
      setLoginLoading(false)
      //navigate to feed page once logged in and signup data was saved
      if(userCredential.user.emailVerified && userDoc){
          navigate('/feed')
      }else{
        await deleteUser(userCredential.user);
        setLoginError('Last signup wasn’t completed. Please sign up again.');      
      }
      
    } catch (error) {
      //handle all firebase errors
      setLoginLoading(false)
      if(error.code === 'auth/user-not-found'){
        setLoginError('user not found')
      }
      else if(error.code === 'auth/invalid-credential'){
        setLoginError('Invalid credentials')
      }else if(error.code === 'auth/invalid-email'){
        setLoginError('Input a valid email!!')
      }
      else if(error.code === 'auth/missing-password'){
        setLoginError('Input a password');
  
      } 
      else if(error.code === 'auth/wrong-password'){
        setLoginError('Wrong password')
      }else{
        setLoginError(error.message)
      }
    }
   
  };

  return { loginWithEmailPassword , loginLoading , loginError , setLoginError };
};

export default useEmailPasswordLogin;


