import {Link} from 'react-router-dom'
const Footer = () =>{
    return(
        <>
                <div className="pre-footer">
                    <div className="pre-footer-container">
                        <div>
                            <div><span>GENERAL</span></div>
                            <div>
                                <ul>
                                <li><Link>Blog</Link></li>
                                <li><Link>Safety</Link></li>
                                <li><Link>FAQ</Link></li>
                                </ul>
                            </div>
                            </div>
                        <div>
                        <div><span>LEGAL</span></div>
                        <div>
                            <ul>
                            <li><Link>Terms of Service</Link></li>
                            <li><Link>Privacy Policy</Link></li>
                            <li><Link>Cookie Policy</Link></li>
                            </ul>
                        </div>
                        </div>
                    </div>
                </div>

        <div className="footer">
          <p>&copy;2024 Vid-Meet. All Right Reserved.</p>
        </div>
        </>
    )
}

export default Footer