import DashboardWrapper from "../../components/DashboardWrapper"
import useFetchUserChats from "../../hooks/use-fetch-userchats"
import { useUser } from "../../context/User-Context"
import { db } from "../../Firebase"
import { doc,getDoc, updateDoc } from "firebase/firestore"
import {  useNavigate } from "react-router-dom";
import '../../styles/ChatPage.css'
import { useState } from "react";
import LineLoadingScreen from "../../components/LineLoadingScreen"
import { EmptyData } from "../../components/EmptyData"
import { GetTime } from "../../components/GetTime"
import { HeaderType3 } from "../../components/HeaderType3"

const ChatPage = () =>{
    const [isMenuOpen , setIsMenuOpen] = useState(false)
    const [isDelete , setIsDelete] = useState(false)
    const { currentUser } = useUser()
    const  navigate  = useNavigate()
    const [imageLoaded , setImageLoaded] = useState(false)

    // const {changeChat } = useChat()

    const   { userChats , userChatsLoading} = useFetchUserChats()
    
    if(userChatsLoading){
        return <LineLoadingScreen/>;
    }
    const handleSelection = async (chat) => {
        const userChatRef = doc(db, 'userchats', currentUser?.uid);
        const userChats = await getDoc(userChatRef);
    
        if (userChats.exists()) {
            const chatsArray = userChats.data().chats;
            const chatIndex = chatsArray.findIndex((c) => c.chatId === chat.chatId);
    
            if (chatIndex !== -1) {
                const updatedChatsArray = [...chatsArray];
                updatedChatsArray[chatIndex] = {
                    ...updatedChatsArray[chatIndex],
                    isSeen: true,
                };
    
                await updateDoc(userChatRef, {
                    chats: updatedChatsArray,
                });
            }
        }
    
        navigate(`/chat/${chat.user.userId}`);
    };


    //we dont want to delete a chat because we want the receiver to still have acces to the chat
    //so we can change isShown to be false for current user
    const handleDeleteChat = async(chat) =>{
        console.log(chat)
        const userChatRef = doc(db, 'userchats', currentUser?.uid);
        const userChats = await getDoc(userChatRef);
    
        if (userChats.exists()) {
            const chatsArray = userChats.data().chats;
            const chatIndex = chatsArray.findIndex((c) => c.chatId === chat.chatId);
    
            if (chatIndex !== -1) {
                const updatedChatsArray = [...chatsArray];
                updatedChatsArray[chatIndex] = {
                    ...updatedChatsArray[chatIndex],
                    isShown: false,
                };
    
                await updateDoc(userChatRef, {
                    chats: updatedChatsArray,
                });
            }
        }

    }

    const handleDeleteState = () =>{
        if(isDelete){
            setIsDelete(false)
            setIsMenuOpen(false)
        }else{
            setIsMenuOpen(false)
            setIsDelete(true)
        }
      
    }

    const options = [{name : isDelete ? 'Cancel Delete' : 'Delete Chat' , action : handleDeleteState}]
    
    console.log(userChats)



    return(
        <>
        <DashboardWrapper title='chat'>
            <HeaderType3 options={options} title='Chat' isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
            <div className="chat-list-container">

                {userChats.length > 0 ? userChats?.map((chat,index)=>(
                    <div className="each-chat" key={index}>
                        <div onClick={() => handleSelection(chat)}>
                            <img onLoad={() => setImageLoaded(true)} src={!imageLoaded ? '../svgs/avatar.svg' : chat.user.userImages[0]} alt="user" />
                        </div>
                        <div onClick={() => handleSelection(chat)}>
                            <span>{chat.user.name} {chat.user?.isPremium && <img src='../svgs/verifiedicon.svg' alt="verified" />}</span>
                            <span>{chat.lastMessage.length > 30 ? `${chat.lastMessage.slice(0, 30)}...` : chat.lastMessage}</span>
                        </div>
                        <div>
                            <div>
                                <span className={!chat.isSeen && 'date-unread'}>{GetTime(chat.updatedAt)}</span>
                            </div>
                            <div>
                                {!chat.isSeen && (
                                <div></div>
                                )}
                            </div>
                            <div>
                                {isDelete && (
                                    <img src="../svgs/Deleteicon.svg" alt="delete" onClick={()=>handleDeleteChat(chat)} />
                                )}
                            </div>
                        </div>

                    </div>
                


                )):<EmptyData title={<>No chats here yet<br/>Start a new conversation! 😃</>}/>}
            </div>

        </DashboardWrapper>
        </>
    )
}

export default ChatPage