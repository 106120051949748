import { HeaderType1 } from "../../components/HeaderType1";
import DashboardWrapper from "../../components/DashboardWrapper";
import '../../styles/ChangePassword.css';
import { useState } from "react";
import { LoadingCircle } from "../../components/LoadingCircle";
import { toast } from "react-toastify";
import { useUser } from "../../context/User-Context";
import { updatePassword, reauthenticateWithCredential, EmailAuthProvider } from "firebase/auth";

const ChangePassword = () => {
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [changePasswordLoading, setChangePasswordLoading] = useState(false);
    const { currentUser } = useUser();

    const changePassword = async () => {
        console.log(currentUser);

        // Validate inputs
        if (!oldPassword || !newPassword || !confirmNewPassword) {
            toast.error('All fields are required');
            return;
        }
        if (newPassword !== confirmNewPassword) {
            toast.error('Password does not match');
            return;
        }
        if (newPassword.length < 6) {
            toast.error('Password must be greater than six characters');
            return;
        }

        try {
            setChangePasswordLoading(true);

            const credential = EmailAuthProvider.credential(currentUser.email, oldPassword);
            await reauthenticateWithCredential(currentUser, credential);

            await updatePassword(currentUser, newPassword);
            toast.success('Password updated successfully');
            setOldPassword('');
            setNewPassword('');
            setConfirmNewPassword('');
        } catch (err) {
            if (err.code === 'auth/wrong-password') {
                toast.error('The old password is incorrect');
            } else if (err.code === 'auth/requires-recent-login') {
                toast.error('Please log in again to change your password');
            } else {
                toast.error('Error changing password');
            }
        } finally {
            setChangePasswordLoading(false);
        }
    };

    return (
        <DashboardWrapper>
            <div>
                <HeaderType1 title='Change Password' />
            </div>
            <div className="change-password-container">
                <div>
                    <div>
                        <label>Old Password</label>
                        <input type="password" value={oldPassword} onChange={(e) => setOldPassword(e.target.value)} />
                    </div>
                    <div>
                        <label>New Password</label>
                        <input type="password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
                    </div>
                    <div>
                        <label>Confirm New Password</label>
                        <input type="password" value={confirmNewPassword} onChange={(e) => setConfirmNewPassword(e.target.value)} />
                    </div>
                    <button onClick={changePassword} disabled={changePasswordLoading}>
                        {changePasswordLoading ? <LoadingCircle /> : 'Change Password'}
                    </button>
                </div>
            </div>
        </DashboardWrapper>
    );
};

export default ChangePassword;