import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/landingPage/Home';
import Login from './pages/login/Login';
import SignUp from './pages/signUp/SignUp';
import Feed from './pages/dashboard/FeedPage';
import ProfileDetails from './pages/dashboard/ProfileDetailsPage';
import PremiumPage from './pages/dashboard/PremiumPage';
import ChatPage from './pages/dashboard/ChatPage';
import EditProfile from './pages/dashboard/EditProfile';
import ToastNotification from './components/Notification';
import EachUser from './pages/dashboard/EachUser';
import EachUserChat from './pages/dashboard/EachUserChat';
import EditPhoto from './pages/dashboard/EditPhotos';
import AccountSettings from './pages/dashboard/AccountSettings';
import ChangePassword from './pages/dashboard/ChangePassword';
import ForgottenPassword from './pages/forgottenPassword/ForgottenPassword';
import Feedback from './pages/dashboard/Feedback';
import { useUser } from './context/User-Context';
import { Navigate } from 'react-router-dom';




function App() {
  const {currentUser}  = useUser()

  return (
    <>
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/login" element={currentUser ? <Navigate to='/feed' /> : <Login />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="/forgottenpassword" element={<ForgottenPassword />} />
      <Route path="/feed" element={currentUser ? <Feed /> : <Navigate to='/login' />} />
      <Route path="/profiledetails" element={currentUser ? <ProfileDetails /> : <Navigate to='/login' />} />
      <Route path="/premium" element={currentUser ? <PremiumPage /> : <Navigate to='/login' />} />
      <Route path="/chat" element={currentUser ? <ChatPage /> : <Navigate to='/login' />} />
      <Route path="/editprofile" element={currentUser ? <EditProfile /> : <Navigate to='/login' />} />
      <Route path="/eachuser/:id" element={currentUser ? <EachUser /> : <Navigate to='/login' />} />
      <Route path="/chat/:id" element={currentUser ? <EachUserChat /> : <Navigate to='/login' />} />
      <Route path="/editphotos" element={currentUser ? <EditPhoto /> : <Navigate to='/login' />} />
      <Route path="/accountsettings" element={currentUser ? <AccountSettings /> : <Navigate to='/login' />} />
      <Route path="/changepassword" element={currentUser ? <ChangePassword /> : <Navigate to='/login' />} />
      <Route path="/feedback" element={currentUser ? <Feedback /> : <Navigate to='/login' />} />
      {/* <Route path="*" element={<Navigate to="/" />} /> */}



    </Routes>
      <ToastNotification/>

    </BrowserRouter>
    </>
    
  );
}

export default App;
