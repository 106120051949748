import { useState , useRef } from "react";
import { LuEyeOff } from "react-icons/lu";
import { LuEye } from "react-icons/lu";


const EmailPassword = ({userData , HandleChangeForm, error , signupError }) =>{
    const [hashed , setHashed] = useState(true)
    
    const inputRef = useRef(null)

    
    const hashPassword = (e) =>{
        !hashed ? inputRef.current.type = 'text' : inputRef.current.type = 'password'
        setHashed(!hashed)
      }


    return(
        <div className="email-password-signup">
            <h1>Almost there! Choose your email and password </h1>
            <p>Email:</p>
            <input name="email" value={userData.email || ''} type="email" placeholder="Email" onChange={HandleChangeForm}/>
            <p>Password:</p>
            <div>
                 <input type='password' value={userData.password || ''} name="password" ref={inputRef} placeholder='Password' onChange={HandleChangeForm} />
                <span onClick={hashPassword}>{!hashed ? <LuEye/> : <LuEyeOff/>}</span>

            </div>
            <p className="error">{error}</p>
            <p className="error">{signupError}</p>


        </div>
    )
}

export default EmailPassword