import '../styles/General.css'
import MenuOptions from "./MenuOptions";

export const HeaderType3 = ({title,options,isMenuOpen,setIsMenuOpen}) =>{


    return(
        <div className="header-type-3">
            <div>
                <span>{title}</span>
            </div>
            <div>
                <img src={isMenuOpen ? "../svgs/UserMenuCancel.svg" : "../svgs/UserMenu.svg"} alt="menu" onClick={()=>setIsMenuOpen(prev => !prev)} />
            </div>
            {isMenuOpen && 
            <div>
                <MenuOptions options={options} />
            </div>
            }

        </div>
    )
}


