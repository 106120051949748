import { setDoc, doc } from 'firebase/firestore';
import { db, storage } from '../Firebase';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { toast } from 'react-toastify';
import { useUser } from '../context/User-Context';

const useEmailPasswordSignup = () => {
  const [secondSignupLoading, setSecondSignupLoading] = useState(false);
  const navigate = useNavigate();
  const { setCurrentUser , currentUser } = useUser();

  const signupWithEmailPassword = async (userData, userCreated) => {
    try {
      setSecondSignupLoading(true);
      await userCreated.reload();

      if (userCreated.emailVerified) {
        let userImageUrl = '';
        if (userData.userImage) {
          const imageRef = ref(storage, `users/${userCreated.uid}/${userData.userImage.name}`);
          await uploadBytes(imageRef, userData.userImage, { contentType: userData.userImage.type });
          userImageUrl = await getDownloadURL(imageRef);
        }
  
        const { contentPreference1, contentPreference2, password, userImage, name, description, ...rest } = userData;
        const contentArray = [contentPreference1 || null, contentPreference2 || null];
        const userDocumentData = {
          ...rest,
          userId : userCreated.uid,
          name: name.trim(),
          description: description.trim(),
          content: contentArray,
          userImages: [userImageUrl],
        };
  
        const userDocRef = doc(db, 'users', userCreated.uid);
        await setDoc(userDocRef, userDocumentData);
  
        const userChatRef = doc(db, 'userchats', userCreated.uid);
        await setDoc(userChatRef, { chats: [] });


        await userCreated.reload();
        // await reloadUser();
        console.log(userCreated)
        setCurrentUser(userCreated)
        
        // Redirect to the feed page
        if (userCreated.emailVerified || currentUser.emailVerified) {
          navigate('/feed');
        };

      }else{
        toast.error('Your email hasn’t been verified yet')
        setSecondSignupLoading(false);

      }

    

    } catch (error) {
      setSecondSignupLoading(false);
    }
  };

  return { signupWithEmailPassword, secondSignupLoading };
};

export default useEmailPasswordSignup;
