import React, { createContext, useContext, useState, useEffect } from "react";
import { auth } from "../Firebase";

const UserContext = createContext();

export const useUser = () => {
    return useContext(UserContext);
};

export const UserProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);
    const [loading, setLoading] = useState(true);

    

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(authUser => {
            if (authUser) {
                authUser.reload()
                if(authUser?.emailVerified || currentUser?.emailVerified){
                    console.log(currentUser , authUser)
                    setCurrentUser(authUser);
                }
            } else {
                setCurrentUser(null);
            }
            setLoading(false);
        });

        return () => unsubscribe();
    }, []);

    return (
        <UserContext.Provider value={{ currentUser , setCurrentUser }}>
            {!loading && children}
        </UserContext.Provider>
    );
};
