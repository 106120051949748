import '../styles/LandingPage.css'


const GeneralLoadingScreen = () =>{
    return(
        <div className="top-loadingscreen-container">
            <div className="loadingscreen-container">
                <img src='../images/logo.png' alt="animated-pic"/>
            </div>
        </div>
    )
}
export default GeneralLoadingScreen