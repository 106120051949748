import '../styles/Feed.css'

const LineLoadingScreen = () =>{


    return(
        <div className="top-lineloading-container" >
            <div className="lineloading-container"></div>
        </div>
    )
}

export default LineLoadingScreen