import '../../styles/LandingPage.css'
import {Link} from 'react-router-dom'
import { IoMenu } from "react-icons/io5";
import { IoCloseSharp } from "react-icons/io5";


import { useState } from 'react';


const Header = () => {
    const [responsive , setResponsive] = useState(false)

    window.onresize = function() {
        const windowWidth = window.innerWidth;
        if(windowWidth > 790){
            setResponsive(false)
        }
    };
   

    const HandleMenu = () =>{
        setResponsive(!responsive)
    }
    return (  
        <>
        <nav>
            <div className='nav-container-1'>
                <ul>
                    <li><Link to='#'>Blog</Link></li>
                    <li><Link to='#'>Pricing</Link></li>
                    <li><Link to='#'>FAQ</Link></li>
                </ul>

            </div>
            <div className='nav-container-2'>
                <div><Link to='/'><img src='../images/logoname.png' alt='logoname'/></Link></div>
            </div>
            <div className='nav-container-3'>
                <ul>
                    <li><Link to='/login'>Log in</Link></li>
                    <li><Link to='/signup'>Sign Up</Link></li>
                </ul>

            </div>
            {/* repsonsive  design*/}
            <div className='nav-logo-responsive'>
                <img src='../images/logo.png' alt='logoname'/>            

                <img src='../images/logoname.png' alt='logoname'/>            
            </div>
            <div className='nav-menu-responsive-logo'>
                {!responsive ? <IoMenu className='menu' onClick={HandleMenu} /> : <IoCloseSharp className='menu' onClick={HandleMenu}  />}
            </div>
        </nav>
        <div className={`${responsive ? 'sidebar-show' : 'sidebar-hide'}`}>
            <div>
                
                <ul>
                    <li><Link to='/login'>Log in</Link></li>
                    <li><Link to='/signup'>Sign Up</Link></li>
                    <li><Link to='#'>Pricing</Link></li>
                    <li><Link to='#'>FAQ</Link></li>
                </ul>



            </div>


        </div>
        </>
    );
}
 
export default Header;