import { useState, useEffect } from "react";
import { db } from '../Firebase';
import { collection, query, where, onSnapshot } from 'firebase/firestore';
import useFetchProfile from "./use-fetch-profile";
import { useUser } from "../context/User-Context";

const useFetchFeed = () => {
    //useuser is the current user
    const { currentUser } = useUser()
    const { profile, profileLoading } = useFetchProfile(currentUser?.uid);
    const [feedLoading, setFeedLoading] = useState(true);
    const [feed, setFeed] = useState([]);

    useEffect(() => {
        //return early if this is false as useeffect has them as dependecies
        if (profileLoading || !profile) {
            setFeedLoading(true); 
            return;
        }
        //fetch only users that isnt current user and in  the same country location
        const ref = collection(db, 'users');
        const q = query(
            ref,
            where('userId', '!=', profile.id), 
            where('country', '==', profile.country) 
        );

        const unsubscribe = onSnapshot(q, (snapshot) => {
            const feedList = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setFeed(feedList); 
            setFeedLoading(false); 
        }, (error) => {
            console.error("Error fetching feed:", error);
            setFeedLoading(false);
        });


        //unsubscribe here when unmounted 
        return () => unsubscribe();

    }, [profileLoading, profile]);

    return { feed, feedLoading };
};

export default useFetchFeed;
