import { MdOutlineKeyboardBackspace } from "react-icons/md";
import '../styles/General.css'
import { useNavigate } from "react-router-dom";
import MenuOptions from "./MenuOptions";

export const HeaderType2 = ({title,options,isMenuOpen,setIsMenuOpen}) =>{

    const navigate = useNavigate()


    return(
        <div className="header-type-2">
            <div>
                <span onClick={()=>{navigate(-1)}}><MdOutlineKeyboardBackspace/></span>
            </div>
            <div>
                <span>{title}</span>
            </div>
            <div>
                <img src={isMenuOpen ? "../svgs/UserMenuCancel.svg" : "../svgs/UserMenu.svg"} alt="menu" onClick={()=>setIsMenuOpen(prev => !prev)} />
            </div>
            {isMenuOpen && 
            <div>
                <MenuOptions options={options} />
            </div>
            }

        </div>
    )
}


