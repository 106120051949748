import '../styles/General.css'

const MenuOptions = ({ options }) => {
  return (
    <div 
      className="top-options-menu" 
    >
      {options?.map((option, index) => (
        <div 
          key={index} 
          className="options-menu" 
          onClick={option?.action} 
        >
          <span>{option?.name}</span>
        </div>
      ))}
    </div>
  );
};

export default MenuOptions;
