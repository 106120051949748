import React, { useEffect, useState } from 'react';
import { FaPlus } from "react-icons/fa6";



const Image = ({userData , HandleChangeForm, error}) => {
    const [image, setImage] = useState(null);

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setImage(URL.createObjectURL(file));
        }
        HandleChangeForm(e)
    };

    useEffect(() => {
        if (userData.userImage) {
            const imageSrc = typeof userData.userImage === 'string' 
                ? userData.userImage 
                : URL.createObjectURL(userData.userImage);
            setImage(imageSrc);
        }
    }, [userData.userImage]); 



    return (
            <div className='image-signup-container'>
                <h1>We need to know what {userData.name} looks like 😃</h1>
                <div>
                    <img src={ image || '../svgs/avatar.svg'} alt='user-image' />
                </div>
                <div>
                    <input name='userImage' accept="image/*" type='file' id="fileInput" onChange={handleImageChange}  />
                    <div>
                     <label  htmlFor="fileInput" ><FaPlus /></label>
                    </div>
                </div>
                <p className='error'>{error}</p>
            </div>
    );
}

export default Image;



