import { useParams } from "react-router-dom"
import useFetchProfile from "../../hooks/use-fetch-profile"
import DashboardWrapper from "../../components/DashboardWrapper"
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { Link } from "react-router-dom";
import getAge from "../../components/GetAge";
import { MdLocationOn } from "react-icons/md";
import { FaUser } from "react-icons/fa";
import { HiOutlineChat } from "react-icons/hi";
import '../../styles/EachUser.css'
import LineLoadingScreen from "../../components/LineLoadingScreen";
import { useNavigate } from "react-router-dom";
import { IoIosPeople } from "react-icons/io";

import { useUser } from "../../context/User-Context";


const EachUser = () =>{
    const { currentUser } = useUser()
    const {id} = useParams()
    const navigate = useNavigate()
    const { profile, profileLoading } = useFetchProfile(id);
 
    if(profileLoading || !profile){
        return <LineLoadingScreen/>
    }

    console.log(profile)

  
        

    return(
        <>
        <DashboardWrapper fade={true}>
            {/* <div className="each-user-header">
                <span><Link to='/feed'><MdOutlineKeyboardBackspace /></Link></span>
                <span>{profile.name}</span>
            </div> */}
            <div className="each-user-container">
            <div className="each-user-image">
                {profile?.userImages && profile.userImages.map((image,index,images)=>(
                    <div className="each-image-container" key={index}>
                        <span onClick={()=>navigate(-1)}><MdOutlineKeyboardBackspace /></span>
                        <img src={image} alt="user"/>
                        <span>{index + 1} / {images.length}</span>
                    </div>
                ))}
                
            </div>
            <div className="each-user-details">
                <div><span>{profile.name},</span><span>{getAge(profile.dateOfBirth)}</span>{profile?.isPremium && <img src="../svgs/verifiedicon.svg" alt="verified"/>}</div>
                <div className="each-user-horizontal-line">
                     <div></div>
                </div>
                <div className="each-user-gender">
                    <span><FaUser /></span>
                    <span>{profile.gender}</span>

                </div>
                <div className="each-user-ethnicity">
                    <span><IoIosPeople /></span>
                    <span>{profile.ethnicity}</span>

                </div>
                <div className="each-user-location">
                    <span><MdLocationOn/></span>
                    <span>{profile.region}, {profile.country}</span>
                </div>
              
                <div className="each-user-bio">
                    <div>
                        <span>Bio</span> 
                    </div>
                    <div>
                        <span>{profile.description}</span>
                    </div>
                </div>
                <div className="each-user-content">
                    <div><span>Content</span></div>
                    <div>
                        <div><span>{profile.content[0]}</span></div>
                        {profile.content[1] && <div><span>{profile.content[1]}</span></div>}
                    </div>

                </div>
                {/* for preview profile we dont want to show the message container */}
                {profile.id !== currentUser?.uid &&
                <div className="message-user">
                    <div>
                    <Link to={`/chat/${profile.id}`}>
                    <span><HiOutlineChat/></span>
                    </Link>
                    </div>

                </div>
                }
            </div>

            </div>
            
        </DashboardWrapper>
        </>
    )
}

export default EachUser