import { useState, useEffect } from "react";

const Location = ({ userData, HandleChangeForm, error }) => {
  const countries = [
    {
        name: 'United Kingdom',
        regions: [
            "Birmingham", "Brighton and Hove", "Bristol", "Cambridge", "Cardiff",
            "Chester", "Coventry", "Derby", "Edinburgh", "Exeter",
            "Glasgow", "Hull", "Leeds", "Leicester", "Lichfield",
            "Liverpool", "London", "Manchester", "Milton Keynes", "Newcastle upon Tyne",
            "Norwich", "Nottingham", "Oxford", "Plymouth", "Portsmouth",
            "Sheffield", "Southampton", "Stoke-on-Trent", "Wakefield", "Wolverhampton",
            "York"
        ]
    },
    {
        name: 'United States',
        regions: [
            "Alabama", "Alaska", "Arizona", "Arkansas", "California", "Colorado", "Connecticut",
            "Delaware", "Florida", "Georgia", "Hawaii", "Idaho", "Illinois", "Indiana", "Iowa",
            "Kansas", "Kentucky", "Louisiana", "Maine", "Maryland", "Massachusetts", "Michigan",
            "Minnesota", "Mississippi", "Missouri", "Montana", "Nebraska", "Nevada", "New Hampshire",
            "New Jersey", "New Mexico", "New York", "North Carolina", "North Dakota", "Ohio",
            "Oklahoma", "Oregon", "Pennsylvania", "Rhode Island", "South Carolina", "South Dakota",
            "Tennessee", "Texas", "Utah", "Vermont", "Virginia", "Washington", "West Virginia",
            "Wisconsin", "Wyoming"
        ]
    }
];



  const [currentCountry, setCurrentCountry] = useState(userData?.country || ""); 
  const [currentRegion, setCurrentRegion] = useState(userData?.region || "");

  const selectCountry = (e) => {
    const selectedCountry = e.target.value;
    setCurrentCountry(selectedCountry);
    setCurrentRegion(""); 
    HandleChangeForm(e); 
  };

  const selectRegion = (e) => {
    const selectedRegion = e.target.value;
    setCurrentRegion(selectedRegion);
    HandleChangeForm(e); 
  };

  const selectedCountryObj = countries.find(country => country.name === currentCountry);

  useEffect(() => {
    if (userData?.country) {
      setCurrentCountry(userData.country);
    }
    if (userData?.region) {
      setCurrentRegion(userData.region);
    }
  }, [userData]);

  return (
    <div className="location-signup-container">
      <h1>Now {userData.name}, we need to know your Location</h1>

      <p>Country:</p>
      <select name="country" onChange={selectCountry} value={currentCountry}>
        <option value={''} disabled></option>
        {countries.map((country) => (
          <option key={country.name} value={country.name}>
            {country.name}
          </option>
        ))}
      </select>

      <p>Region:</p>
      <select 
        name="region" 
        onChange={selectRegion} 
        value={currentRegion} 
        disabled={!currentCountry}
      >
        <option value={''} disabled></option>
        {currentCountry && selectedCountryObj?.regions.map((region) => (
          <option key={region} value={region}>
            {region}
          </option>
        ))}
      </select>
      {error && <p className="error">{error}</p>}
    </div>
  );
};

export default Location;
