import React, { useRef, useEffect } from "react";
import useFetchFeed from "../../hooks/use-fetch-feed";
import { BiSolidCamera } from "react-icons/bi";
import { MdLocationOn } from "react-icons/md";
import { LuSettings2 } from "react-icons/lu";
import "../../styles/Feed.css";
import DashboardWrapper from "../../components/DashboardWrapper";
import getAge from "../../components/GetAge";
import { Link } from "react-router-dom";
import { EmptyData } from "../../components/EmptyData";
import LineLoadingScreen from "../../components/LineLoadingScreen";
import { FormatContent } from "../../components/FormatContent";

const Feed = () => {
  const { feed, feedLoading } = useFetchFeed();
  const feedContainerRef = useRef(null);

  useEffect(() => {
    if (feed && !feedLoading && feedContainerRef.current) {
      const lastScrollPosition = Number(localStorage.getItem("scrollPosition"));
      if (lastScrollPosition) {
        feedContainerRef.current.scrollTop = lastScrollPosition;
      }
    }
  }, [feed, feedLoading]);
  console.log(feed)

  const handleScroll = (e) => {
    const currentScroll = e.target.scrollTop;
    localStorage.setItem("scrollPosition", currentScroll);
  };

  if (feedLoading || !feed) {
    return <LineLoadingScreen />;
  }

  return (
    <DashboardWrapper title="home" fade={true}>
      <div className="top-homefeed-container">
        <div
          className="homefeed-container"
          onScroll={handleScroll}
          ref={feedContainerRef}
        >
          {feed.length > 0 ? (
            <>
              {feed.map((feedItem) => (
                <div className="feed-container" key={feedItem.id}>
                  <img src={feedItem.userImages[0]} alt="user" />
                  <div>
                    <div>
                      <Link to={`/eachuser/${feedItem.id}`}>
                        <span>{feedItem.name}</span>,<span>{getAge(feedItem.dateOfBirth)}</span>{feedItem?.isPremium && <img src="../svgs/verifiedicon.svg"  alt="verified"/>}

                      </Link>
                    </div>
                    <div>
                      <span>
                        <BiSolidCamera />
                      </span>
                      <span>
                        {FormatContent(feedItem.content[0],feedItem.content[1])}
                      </span>
                    </div>
                    <div>
                      <span>
                        <MdLocationOn />
                      </span>
                      <span>
                        {feedItem.region}, {feedItem.country}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
              <div className="end-scroll">
                <p>End of scroll...</p>
              </div>
            </>
          ) : (
            <EmptyData title={<>Your Feed is empty.<br />Try Refreshing your Page!😔</>} />
          )}
        </div>
        <div className="filter">
          <LuSettings2 />
        </div>
      </div>
    </DashboardWrapper>
  );
};

export default Feed;
