import { Link } from "react-router-dom"
import '../../styles/ForgottenPassword.css'
import Footer from '../landingPage/Footer'
import { useState } from 'react';
import { LoadingCircle } from '../../components/LoadingCircle';
import { sendPasswordResetEmail } from "firebase/auth";
import { auth, db } from "../../Firebase";
import { collection, getDocs, query, where } from "firebase/firestore";
import { useNavigate } from "react-router-dom";


const ForgottenPassword = () =>{

    const [email ,setEmail] = useState('')
    const [emailError , setEmailError] = useState('')
    const [emailLoading , setEmailLoading] = useState(false)
    const [emailSent , setEmailSent] = useState(false)
    const navigate = useNavigate()

    

    const HandleEmail = async (e) => {
        setEmailError('')
        e.preventDefault();
    
        if (!email) {
            setEmailError("Email is required");
            return;
        }
    
        if (!email.includes("@") || !email.includes(".")) {
            setEmailError("Email is invalid");
            return;
        }
    
        const ref = collection(db, "users");
        const q = query(ref, where("email", "==", email));
    
        try {
            setEmailLoading(true);
    
            const querySnapshot = await getDocs(q);
            console.log("Query result:", querySnapshot.empty);
    
            if (querySnapshot.empty) {
                setEmailError("Email not recognized");
                return;
            }
            await sendPasswordResetEmail(auth, email);
            setEmailSent(true)
            //users should be done reading the email message redirect to login page auto after 10seconds
            setTimeout(()=>{
                navigate('/login')
            },10000)
        } catch (error) {
            if (error.code === "auth/invalid-email") {
                setEmailError("Invalid email format. Please check and try again.");
            } else if (error.code === "auth/network-request-failed") {
                setEmailError("Network error. Please try again later.");
            } else {
                setEmailError("An unexpected error occurred. Please try again.");
            }
        } finally {
            setEmailLoading(false);
    
        }
    };
    

    return(
        <>
        <div className='top-login-header'>
            <div className='login-header'> 
                <div><Link to='/'><img src='../images/logoname.png' alt='logoname'/></Link></div>
                <div>
                    <div><span><Link to='/login'>Login</Link></span></div>
                    <div><span><Link to='/signup'>Sign Up</Link></span></div>

                </div>
            </div>
        </div>
        <div className='password-forgotten-body'>
            
            <div className='password-forgotten-form'>
                {!emailSent ? 
                <>
                <div>
                    <span>Forgotten Password</span>
                </div>
                <form>
                    <div>
                        <input type='text' placeholder='Your Email' value={email} onChange={(e)=> setEmail(e.target.value)} />
                    </div>
                    <div>
                        <button disabled={emailLoading} onClick={(e)=>HandleEmail(e)}>{!emailLoading ? 'Next' : <LoadingCircle/>}</button>
                    </div>
                    <p className='error'>{emailError}</p>
                </form>
                </>
                : <div className="password-sent">
                    <img src="../svgs/inbox.svg" alt="inbox"/>
                    <p>We've sent a password reset link to your email! Check your inbox 
                        and click the link to set a new password.</p>
                </div>
                }
            </div>
               
              
        </div>
        <div className='login-footer'>
         <Footer/>
        </div>
        </>
    )
}

export default ForgottenPassword
