const EthnicityAbout = ({userData , HandleChangeForm , error}) =>{

    const Ethnicities = ['Asian', 'Black', 'Caucasian (white)', 'Hispanic', 'Middle Eastern', 'Mixed Race', 'South Asian'];

    return(
        <div className="location-signup-container">
        <h1>Interesting, so {userData.name}...</h1>
        <p>What is your ethnicity?</p>
        <select name="ethnicity" onChange={HandleChangeForm} value={userData.ethnicity || null} defaultValue='invalid-option'>
            <option value='invalid-option' disabled></option>
            {Ethnicities.map((ethnicity,index) => (
                <option key={index} value={ethnicity}>
                    {ethnicity}
                </option>
            ))}
        </select>
        <p>Write a little about yourself (Bio)</p>
        <textarea name="description" onChange={HandleChangeForm} value={userData.description ||null}  placeholder="e.g I love creating fashion content and would love a partner to collaborate with!">

        </textarea>
        <p className="error">{error}</p>
    </div>
    )
}

export default EthnicityAbout