import useSignOut from "../../hooks/use-signout";
import DashboardWrapper from "../../components/DashboardWrapper";
import getAge from "../../components/GetAge";
import '../../styles/ProfileDetails.css'
import { Link } from "react-router-dom";
import useFetchProfile from "../../hooks/use-fetch-profile";
import { useUser } from "../../context/User-Context";
import {  useState } from "react";
import LineLoadingScreen from "../../components/LineLoadingScreen";
import { useNavigate } from "react-router-dom";
import { HeaderType3 } from "../../components/HeaderType3";



const ProfileDetailsPage = () =>{
    const navigate = useNavigate()
    const [isMenuOpen , setIsMenuOpen] = useState(false)
    const { currentUser } = useUser();
    const [imageloaded , setImageLoaded] = useState(false)

    const { profile, profileLoading } = useFetchProfile(currentUser?.uid);

    const {signOut} = useSignOut()
    
    if(!profile || profileLoading){
    
        return(
            <LineLoadingScreen/>
        ) 
    }



    const handleEditProfile = () =>{
        navigate('/editprofile')
    }

    const handlePreviewProfile = () =>{
        navigate( `/eachuser/${profile.id}`)
    }

    const handleAccountSetings = () =>{
        navigate('/accountsettings')
    }

    const addPhotos = () =>{
        navigate('/editphotos')
    }

    const handleFeedback = () =>{
        navigate('/feedback')
    }


    const options = [{name : 'Edit Profile' , action : handleEditProfile} ,   {name : 'Edit Photos' , action : addPhotos}, {name : 'Preview Profile' , action : handlePreviewProfile} ,
       {name : 'Account Settings' , action : handleAccountSetings} , {name : 'Feedback' ,  action : handleFeedback}]

    
  
    return(
        <>
        <DashboardWrapper title='profile' fade={true}>
            {profile &&
                <div className="profiledetails-container">
                    <HeaderType3 title='My Profile' options={options} isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen}/>
                    <div className="name-photo-container">
                        <div>
                            
                        </div>
                        <div>
                            <img alt="profile-image" onLoad={()=>setImageLoaded(true)} src={ !imageloaded ? '../svgs/avatar.svg' : profile.userImages[0] } />
                            <img src="../svgs/plus.svg" alt="add" onClick={()=>{navigate('/editphotos')}}/>
                        </div>
                        <div>
                            <span>{profile.name}, {getAge(profile.dateOfBirth)}</span>{profile?.isPremium && <img src="../svgs/verifiedicon.svg" alt="verified"/>}
                        </div>
                        <div>

                        </div>
                </div>
                 <div className="profile-settings-text">
                        <span>Profile Settings</span>
                        <span><Link to='/editprofile'><img src="../svgs/edit.svg" alt="edit"/></Link></span>
                </div>
                <div className="profile-settings">
                    <div>
                        <span>Name</span>
                        <span>{profile.name}</span>
                    </div>
                    <div>
                        <span>Gender</span>
                        <span>{profile.gender}</span>
                    </div>
                    <div>
                        <span>Ethnicity</span>
                        <span>{profile.ethnicity}</span>
                    </div>
                    <div>
                        <span>Date of Birth</span>
                        <span>{profile.dateOfBirth}</span>
                    </div>
                    <div>
                        <span>Email</span>
                        <span>{profile.email}</span>
                    </div>
                 
                    <div>
                        <span>Bio</span>
                        <span>{profile.description.slice(0,25)}..</span>
                    </div>
                    <div>
                        <span>Content-type-1</span>
                        <span>{profile.content[0] || 'not-selected'}</span>
                    </div>
                    <div>
                        <span>Content-type-2</span>
                        <span>{profile.content[1] || 'not-selected'}</span>
                    </div>
                    <div>
                        <span>Location</span>
                        <span>{profile.region},{profile.country}</span>
                    </div>
                </div>
            <div className="signout-container">
             <button onClick={signOut}>Logout</button>
             </div>
            </div>
            }
            
         
        </DashboardWrapper>
       
        </>
    )

}
export default ProfileDetailsPage