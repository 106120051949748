import { MdOutlineKeyboardBackspace } from "react-icons/md";
import '../styles/General.css'
import { useNavigate } from "react-router-dom";

export const HeaderType1 = ({title}) =>{
    const navigate = useNavigate()
    return(
        <div className="header-type-1">
            <div>
                <span onClick={()=>{navigate(-1)}}><MdOutlineKeyboardBackspace/></span>
            </div>
            <div>
                <span>{title}</span>
            </div>

        </div>
    )
}


