const GenderAge = ({userData , HandleChangeForm, error }) =>{

    const maxDate = new Date().toISOString().split('T')[0]

    return(
        <>
        <div className="gender-age-container">
          <h1>Got it, {userData.name.trim()}! </h1>
          <p>What is your gender?</p>

        <form>
            <div>

                <div> 
                    <label htmlFor='male'>Male</label>
            
                    <input type="radio" id='male' value='Male' checked={userData.gender === 'Male'} name="gender" onChange={(e) => HandleChangeForm(e)}
               
                    />
                </div>
                <div>
                    <label htmlFor='female'>Female</label>

                    <input type="radio" id='female' value='Female' checked={userData.gender === 'Female'} name="gender" onChange={(e) => HandleChangeForm(e)}/>       
                </div>
                <div>
                    <label htmlFor='non-binary'>Non-Binary</label>
            
                    <input type="radio" id='non-binary' value='Non-binary' checked={userData.gender === 'Non-binary'} name="gender" onChange={(e) => HandleChangeForm(e)}/>
            
                </div>
            </div>
        
            <div>
                <p htmlFor="dob">What is your date of birth?</p>
                <input id='dob' max={maxDate} type='date' name='dateOfBirth' value={userData.dateOfBirth || null}  onChange={(e) => HandleChangeForm(e)} />
            </div>        
            <p className="error">{error}</p>

        </form>
        </div>
        
        </>
    )
}

export default GenderAge